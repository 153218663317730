<template>
    <v-row no-gutters>
        <v-col cols="12">
            <RealmBar :enterpriseId="enterpriseId" :realm="realm" class="mb-6" v-if="realm"></RealmBar>
            <AccessDeniedOverlay v-if="forbiddenError"></AccessDeniedOverlay>
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard', params: { realm: this.$route.params.realmId } }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-enterprise-list', params: { realm: this.$route.params.realmId } }">Enterprises</router-link>
                    <router-link :to="{ name: 'cryptium_id-realm-dashboard', params: { enterpriseId: this.$route.params.enterpriseId, realmId: this.$route.params.realmId } }">Realm</router-link>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10" v-if="realm">
                <v-col cols="12" class="pa-0">
                    <v-card>
                        <v-toolbar color="indigo" dark flat dense>
                        <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                        <v-toolbar-title>
                            Profile requests
                        </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                        <p class="mb-0 pb-0">
                            Report period: <v-select v-model="recentDays" :items="recentDaysItems" outlined dense></v-select>
                        </p>
                        <v-alert type="error" v-if="isViewReady && !Array.isArray(profileRequestList)">
                            Failed to load profile request list
                        </v-alert>
                        </v-card-text>
                        <v-simple-table v-if="Array.isArray(profileRequestList)">
                            <template #default>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Created On</th>
                                        <th>Domain</th>
                                        <th>Brandprofile</th>
                                        <th>Required</th>
                                        <th>Input</th>
                                        <th>Quick</th>
                                        <th>Dev</th>
                                        <!-- <th>Content</th> -->
                                        <th>Not After</th>
                                        <th>Response</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="profileRequest in profileRequestList" :key="profileRequest.id">
                                        <td>{{ profileRequest.id }}</td>
                                        <td>{{ formatDateTime(profileRequest.created_on) }}</td>
                                        <!-- NOTE: there's a bug on the server that the entire record is duplicated in 'content', instead of just the 'content' being there; so when that bug is fixed this might break and just remove the second layer of 'content' to fix it -->
                                        <td>{{ profileRequest.content.content.domain }}</td>
                                        <td>{{ profileRequest.content.content.brandprofile }}</td>
                                        <td>{{ profileRequest.content.content.required.join(', ') }}</td>
                                        <td>{{ Object.keys(profileRequest.content.content.input).length > 0 ? JSON.stringify(profileRequest.content.content.input, null, 2) : '' }}</td>
                                        <td>{{ profileRequest.content.content.is_quick }}</td>
                                        <td>{{ profileRequest.content.content.is_dev }}</td>
                                        <!-- <td><pre>{{ JSON.stringify(profileRequest.content, null, 2) }}</pre></td> -->
                                        <td>{{ formatDate(profileRequest.not_after) }}</td>
                                        <td><pre>{{ JSON.stringify(profileRequest.profile_response?.content, null, 2) }}</pre></td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
import { toMillis } from '@libertyio/time-util-js';
import AccessDeniedOverlay from '@/components/AccessDeniedOverlay.vue';
import RealmBar from '@/components/cryptium_id/RealmBar.vue';
import { integerOrNull } from '@/sdk/input';

export default {
    components: {
        AccessDeniedOverlay,
        RealmBar,
    },
    data: () => ({
        realm: null,
        forbiddenError: false,
        profileRequestList: null,
        error: null,
        recentDays: 1, // how many days back to look for new records
        recentDaysItems: [
            { value: 1, text: '1 day' },
            { value: 7, text: '7 days' },
            { value: 14, text: '14 days' },
        ],
        isViewReady: false,
    }),
    computed: {
        enterpriseId() {
            return this.$route.params.enterpriseId;
        },
        realmId() {
            return this.$route.params.realmId;
        },
    },
    watch: {
        recentDays() {
            this.loadProfileRequestList(); // reload the report with the new value
        },
    },
    methods: {
        async loadRealm() {
            try {
                this.$store.commit('loading', { loadRealm: true });
                // const result = await this.$client.enterprise(this.$route.params.enterpriseId).realm.get({ id: this.$route.params.realmId });
                // const result = await this.$client.cryptiumIdRealm(this.$route.params.enterpriseId, this.$route.params.realmId).self.get();
                const result = await this.$client.cryptiumId(this.$route.params.enterpriseId).realm.get({ id: this.$route.params.realmId });
                console.log(`realm/loginshield.vue loadRealm result: ${JSON.stringify(result)}`);
                if (result) {
                    this.realm = result;
                }
            } catch (err) {
                console.error('realm/loginshield.vue loadRealm failed', err);
                const message = err.message ? err.message : null;
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load realm', message });
            } finally {
                this.$store.commit('loading', { loadRealm: false });
            }
        },
        async loadProfileRequestList() {
            try {
                // const match = { enterprise_id: this.$route.params.enterpriseId, realm_id: this.$route.params.realmId };
                // const result = await this.$client.enterprise(this.$route.params.enterpriseId).realmUser.search(match);
                // TODO: add recent X days to query as input parameters and add a selection widget to this page
                const query = {
                    not_before: Date.now() - toMillis({ days: this.recentDays }),
                };
                const result = await this.$client.cryptiumIdRealm(this.$route.params.enterpriseId, this.$route.params.realmId).realmProfileRequest.search(query);
                console.log(`loadProfileRequestList: ${JSON.stringify(result)}`);
                if (result && result.list) {
                    this.profileRequestList = result.list;
                }
            } catch (err) {
                console.error('loadProfileRequestList: failed to load request list', err);
            } finally {
                this.isViewReady = true;
            }
        },
        formatDate(timestamp) {
            const d = new Date(timestamp);
            return d.toDateString();
        },
        formatDateTime(timestamp) {
            const d = new Date(timestamp);
            return d.toString();
        },
    },
    mounted() {
        this.loadRealm();
        this.loadProfileRequestList();
        if (this.$route.query.recent_days) {
            this.recentDays = integerOrNull(this.$route.query.recent_days) ?? 1;
        }
    },
};
</script>
